<template>
	<div :style="{height: height + 'px'}" id="lineCharts"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
	name: "lineCharts",
	props: {
		height: {
			type: Number,
			default: 400
		},
		dataValue: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			charts: null,
			options: {
				color: ['rgba(91, 131, 253, .6)', 'rgba(64, 213, 154, .6)'],
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						label: {
							backgroundColor: '#6a7985'
						}
					}
				},
				legend: {
					data: [],
					right: 20
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '1%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: false,
						data: []
					}
				],
				yAxis: [
					{
						type: 'value'
					}
				],
				series: []
			}
		}
	},
	mounted() {
		this.charts = echarts.init(document.getElementById('lineCharts'));
		window.setTimeout(() => {
			this.resize()
		}, 300)
		window.addEventListener('resize', this.resize)
	},
	methods: {
		resize() {
			if (this.charts) {
				this.charts.resize();
			}
		},
		init(v) {
			this.options.legend.data = v.compare_revenues?.length > 0 ? ['对比日期段', '查询日期段'] : ['查询日期段'];
			this.options.xAxis[0].data = v.dates;
			let series = []
			if (v.compare_revenues?.length > 0) {
				series.push(
						{
							name: '对比日期段',
							type: 'line',
							smooth: true,
							areaStyle: {},
							emphasis: {
								focus: 'series'
							},
							data: v.compare_revenues
						}
				)
			}
			series.push(
					{
						name: '查询日期段',
						type: 'line',
						smooth: true,
						stack: 'Total',
						areaStyle: {},
						emphasis: {
							focus: 'series'
						},
						data: v.revenues
					}
			)
			this.options.series = series
			if (this.charts) {
				this.charts.clear()
				this.charts.setOption(this.options);
			}
		}
	},
	destroyed() {
		window.removeEventListener('resize', this.resize)
	},
	watch: {
		dataValue: {
			handler(v) {
				this.init(v)
			}
		}
	}
}
</script>

<style scoped>

</style>
