<template>
	<div>
		<d-table title="支付统计" :header="['支付方式', '收入（元）', '订单数']" :desserts="item.payInfo"/>
		<d-table class="mt-10" title="产品统计" :header="['产品类型', '收入（元）', '订单数']" :desserts="item.prodInfo"/>
	</div>
</template>

<script>
import dTable from './d-table'

export default {
	name: "pieTable",
	components: {
		dTable
	},
	props: {
		height: {
			type: Number,
			default: 500
		},
		item: {
			type: Object,
			default: ()=> ({})
		}
	}
}
</script>

<style scoped lang="scss">
::v-deep {
	.v-data-table__wrapper {
		table > tbody {
			tr > td {
				border-bottom: none !important;
				color: #333333;
				font-size: 14px !important;
				height: 48px;
				line-height: 48px;
			}

			tr:hover {
				background-color: #ffffff !important;
				box-shadow: unset;
			}

			.v-data-table__empty-wrapper:hover {
				box-shadow: unset;
			}
		}

	}
}

</style>
