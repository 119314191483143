<template>
	<div style="overflow: hidden">
		<div v-if="hasPermissions">
			<v-row>
				<v-col cols="7">
					<div class="d-card">
						<div class="d-title">今日数据</div>
						<div class="mt-4">
							<v-row>
								<v-col cols="4">
									<overview-card :value="registerUser"/>
								</v-col>
								<v-col cols="4">
									<overview-card
											:value="orderCount"
											title="订单数量"
											icon="quanbudingdan"
											background="#D7F5E9"
											avatarColor="#40D59A"/>
								</v-col>
								<v-col cols="4">
									<overview-card
											:value="salesAmount"
											title="销售金额"
											icon="dingdancaiwu"
											background="#FCF3DA"
											avatarColor="#FCCB4F"/>
								</v-col>
							</v-row>
						</div>
					</div>
					<div class="d-card mt-6">
						<div class="d-title">营业收入</div>
						<div>
							<search :rangeTimeText="searchTime" @change="revenuesCompare"/>
						</div>
						<line-charts ref="lineChartsRef" class="mt-4" :height="lineHeight" :dataValue="lineDataValue"/>
					</div>
				</v-col>
				<v-col cols="5">
					<div class="d-card">
						<div>
							<div style="float: left" class="d-title">收入统计</div>
							<div style="float: right; cursor: pointer" @click="changeType">
								<v-icon class="iconfont" size="24" color="primary">iconfont icon-{{ type }}</v-icon>
							</div>
							<div style="clear: both"></div>
						</div>
						<div class="mt-3" :style="{height: rightHeight + 'px'}">
							<range-time label="订单日期范围" :value="orderTime" @change="changeOrderTime" :recent30day="true"/>
							<template v-if="type === 'biaoge'">
								<pie-table :item="revenues"/>
							</template>
							<template v-else>
								<pie-card :height="pieHeight" :item="revenues"/>
							</template>
						</div>
					</div>
				</v-col>
			</v-row>
		</div>
		<div v-else>
			<div style="height: calc(100vh - 104px)">
				<span class="main-title">
					{{ title }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import overviewCard from "@/components/dashboard/overview-card.vue";
import search from "@/components/dashboard/search";
import rangeTime from "@/components/dateTime/rangeTime.vue";
import lineCharts from "@/components/dashboard/lineCharts";
import pieCard from "@/components/dashboard/pie-card";
import pieTable from "@/components/dashboard/pieTable";
import moment from "moment";

export default {
	name: "index",
	components: {
		overviewCard,
		search,
		rangeTime,
		lineCharts,
		pieCard,
		pieTable
	},
	data() {
		return {
			title: '景区一体化平台',
			hasPermissions: false,
			type: 'biaoge',
			lineHeight: 400,
			pieHeight: 400,
			// 操作权限
			operatePermissions: {},
			api: '/api/statistic/dailystat_index',
			registerUser: 0,
			orderCount: 0,
			salesAmount: 0,
			revenues: {},
			searchTime: '',
			orderTime: '',
			lineDataValue: {},
			rightHeight: 400
		}
	},
	created() {
		let str = sessionStorage.getItem('operatePermissionKey');
		if (str) {
			this.operatePermissions = JSON.parse(str);
			if (this.operatePermissions.includes('82900')) {
				this.hasPermissions = true;
				// 今日统计
				this.loadBoxHeight();
				// 收入统计
				this.searchTime = moment().add(-7, 'days').format('YYYY-MM-DD') + ' ~ ' + moment().format('YYYY-MM-DD');
				this.orderTime = this.searchTime
				this.revenuesStatistic();
			}
		}
	},
	mounted() {
		let item = localStorage.getItem('common-setting');
		if (item) {
			this.title = JSON.parse(item).title;
		}
		this.initLineEcharts()
		window.addEventListener('resize', this.getContentHeight)
	},
	methods: {
		changeType() {
			this.type === 'biaoge' ? this.type = 'bingtu' : this.type = 'biaoge';
		},
		initLineEcharts() {
			window.setTimeout(() => {
				this.getContentHeight()
			}, 500)
		},
		getContentHeight() {
			const clientHeight = document.documentElement.clientHeight;
			const headerHeight = 56;
			const spacing = 12 * 2 + 10;
			this.rightHeight = (clientHeight - headerHeight - spacing - 12 * 2 - 24 * 2 - 12)
			this.lineHeight = clientHeight - headerHeight - 219 - 12 * 2 - spacing - 24 * 2 - 110
			this.pieHeight = (clientHeight - headerHeight - spacing - 12 * 2 - 24 * 2 - 101) / 2 - 70
			window.setTimeout(() => {
				this.$refs.lineChartsRef && this.$refs.lineChartsRef.resize()
			}, 300)
		},
		// 今日数据统计
		loadBoxHeight() {
			this.axios.post(this.api, {types: 'new_user,new_res,new_amount'}).then((res) => {
				if (res.data) {
					this.registerUser = res.data.new_user.user
					this.orderCount = res.data.new_res.col
					this.salesAmount = res.data.new_amount.amount
				}
			})
		},
		// 营业收入
		revenuesCompare(params) {
			this.axios.post(this.api, Object.assign({types: 'revenues_compare'}, params)).then((res) => {
				if (res.data) {
					this.lineDataValue = res.data.revenues_compare;
				}
			})
		},
		// 收入统计
		revenuesStatistic() {
			if (this.orderTime) {
				const array = this.orderTime.split(' ~ ')
				const start = array[0]
				const end = array[1]
				this.axios.post(this.api, {
					types: 'revenues_statistic',
					revenues_statistic: {startDate: start, endDate: end}
				}).then((res) => {
					if (res.data) {
						this.revenues = res.data.revenues_statistic
					}
				})
			}
		},
		changeOrderTime(v) {
			this.orderTime = v;
			this.revenuesStatistic();
		}
	},
	destroyed() {
		window.removeEventListener("resize", this.getContentHeight);
	}
}
</script>

<style scoped lang="scss">
.main-title {
	position: absolute;
	top: 50%;
	font-size: 40px;
	transform: translateY(-50%) translateX(-50%);
	left: 50%;
}

.d-card {
	background: #ffffff;
	border-radius: 8px;
	padding: 24px;
}

.d-title {
	font-size: 18px;
	font-weight: bold;
	color: #333333;
}
</style>
