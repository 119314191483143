<template>
	<div class="d-flex justify-center" style="width: 350px; margin-bottom: 40px">
		<v-col :cols="4" v-for="(item, index) in array" :key="index">
			<div
					style="width: 12px; height: 12px; border-radius: 50%; float: left"
					:style="{background: colors[index]}"></div>
			<div style="font-size: 13px; color: #666666; float: left; margin-left: 10px; margin-top: -3px">
				{{ item.type }}
			</div>
			<div style="clear: left"/>
		</v-col>
	</div>
</template>

<script>
export default {
	name: "pie-point",
	props: {
		colors: {
			type: Array,
			default: () => []
		},
		items: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			array: []
		}
	},
	watch: {
		items: {
			handler(v) {
				this.array = []
				v.forEach((item, index) => {
					if (index !== v.length - 1 && (item.num > 0 || item.amount > 0)) {
						this.array.push(item)
					}
				})
			},
			immediate: true
		}
	}
}
</script>

<style scoped>

</style>
