<template>
	<div>
		<v-row>
			<v-col cols="6">
				<pie-charts
						:colorList="colors"
						:height="height"
						title="支付方式-收入"
						radius="70%"
						:items="item.payInfo"
						type="amount"/>
			</v-col>
			<v-col cols="6">
				<pie-charts
						:colorList="colors"
						id="pieCharts2"
						:height="height"
						title="支付方式-订单数"
						:items="item.payInfo"
						type="num"/>
			</v-col>
		</v-row>
		<pie-point :colors="colors" :items="item.payInfo"/>
		<v-row>
			<v-col cols="6">
				<pie-charts
						:colorList="colors"
						id="pieCharts3"
						:height="height"
						radius="70%"
						title="产品类型-收入"
						:items="item.prodInfo"
						type="amount"/>
			</v-col>
			<v-col cols="6">
				<pie-charts
						:colorList="colors"
						id="pieCharts4"
						:height="height"
						title="产品类型-订单数"
						:items="item.prodInfo"
						type="num"/>
			</v-col>
		</v-row>
		<pie-point :colors="colors" :items="item.prodInfo"/>
	</div>
</template>

<script>
import pieCharts from "@/components/dashboard/pieCharts";
import piePoint from "./pie-point";

export default {
	name: "pie-card",
	components: {
		pieCharts,
		piePoint
	},
	props: {
		height: {
			type: Number,
			default: 400
		},
		item: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			colors: ['#5B83FD', '#40D59A', '#FCCA4F', '#E97FED', '#ED6060', '#E7E7E7'],
		}
	}
}
</script>

<style scoped>

</style>
