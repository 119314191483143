<template>
	<div>
		<v-row>
			<v-col cols="4">
				<range-time :recent30day="true" label="查询日期范围" :value="rangeTimeText" @change="change($event, 1)"/>
			</v-col>
			<v-col cols="8">
				<div style="float: left; width: 140px">
					<v-checkbox label="对比日期段" @change="checkChange"/>
				</div>
				<div v-if="compareTheTime" style="float: right; width: calc(100% - 140px)">
					<range-time :recent30day="true" label="对比日期范围" :value="rangeTimeText" @change="change($event, 2)"/>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import rangeTime from "@/components/dateTime/rangeTime.vue";

export default {
	components: {
		rangeTime
	},
	props: {
		rangeTimeText: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			compareTheTime: false,
			form: {}
		}
	},
	methods: {
		change(v, type) {
			const array = v.split(' ~ ');
			if (type === 1) {
				this.form['startDate'] = array[0];
				this.form['endDate'] = array[1];
			} else if (type === 2) {
				this.form['compare_start'] = array[0];
				this.form['compare_end'] = array[1];
			}
			this.$emit('change', {revenues_compare: this.form})
		},
		checkChange(v) {
			this.compareTheTime = v;
			if (!v) {
				this.form['compare_start'] = null;
				this.form['compare_end'] = null;
				this.$emit('change', {revenues_compare: this.form})
			}
		}
	}
}
</script>
