<template>
	<div class="d-card" :style="{background: background}">
		<div style="float: left">
			<div style="font-size: 32px; font-weight: bold">
				<template v-if="init || newValue.toString().includes('.')">
					{{ newValue }}
				</template>
				<template v-else>
					<count-to
							:startVal="oldValue"
							:endVal="newValue"
							:duration="2000"
					/>
				</template>
			</div>
			<div style="font-size: 14px; margin-top: 6px">{{ title }}</div>
		</div>
		<div style="float: right">
			<v-avatar
					:color="avatarColor"
					size="80"
			>
				<v-icon color="#ffffff" size="40" class="iconfont">iconfont icon-{{ icon }}</v-icon>
			</v-avatar>
		</div>
		<div style="clear: both"></div>
	</div>
</template>

<script>
import countTo from "vue-count-to"
export default {
	name: "overview-card",
	components: {
		countTo
	},
	props: {
		background: {
			type: String,
			default: '#DEE6FF'
		},
		title: {
			type: String,
			default: '注册人数'
		},
		icon: {
			type: String,
			default: 'renshu'
		},
		value: {
			type: Number,
			default: 0
		},
		avatarColor: {
			type: String,
			default: '#5B83FD'
		}
	},
	data() {
		return {
			oldValue: 0,
			newValue: 0,
			oldValue1: 0,
			init: true
		}
	},
	watch: {
		value: {
			handler(v) {
				this.oldValue1 = v;
				this.newValue = v;
			}
		},
		'$store.state.indexDashboard': {
			handler(v) {
				if (v) {
					switch (this.title) {
						case '注册人数':
							this.newValue = v.new_user.user
							break;
						case '订单数量':
							this.newValue = v.new_res.col
							break;
						case '销售金额':
							this.newValue = v.new_amount.amount
							break;
						default:
							break;
					}
					this.oldValue = this.oldValue1
					this.oldValue1 = this.newValue
					this.init = false
				}
			}
		}
	}
}
</script>

<style scoped lang="scss">
.d-card {
	height: 128px;
	width: 100%;
	border-radius: 8px;
}
</style>
