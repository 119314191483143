<template>
	<div>
		<div style="font-size: 14px; font-weight: bold">{{ title }}</div>
		<div class="mt-6">
			<v-simple-table>
				<template v-slot:default>
					<thead>
					<tr>
						<th v-for="(item, index) in header" :key="index" class="text-left">
							{{ item }}
						</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="item in desserts" :key="item.name">
						<td>{{ item.type }}</td>
						<td>{{ item.amount }}</td>
						<td>{{ item.num }}</td>
					</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
	</div>
</template>

<script>
export default {
	name: "d-table",
	props: {
		title: {
			type: String,
			default: ''
		},
		header: {
			type: Array,
			default: () => []
		},
		desserts: {
			type: Array,
			default: () => []
		}
	}
}
</script>

<style scoped>

</style>
