<template>
	<div :style="{height: height + 'px'}" :id="id"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
	name: "pieCharts",
	props: {
		height: {
			type: Number,
			default: 400
		},
		id: {
			type: String,
			default: 'pieCharts1'
		},
		title: {
			type: String,
			default: ''
		},
		radius: {
			type: [String, Array],
			default: () => ['50%', '70%']
		},
		colorList: {
			type: Array,
			default: () => []
		},
		items: {
			type: Array,
			default: () => []
		},
		type: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			charts: null,
			options: {
				title: {
					text: this.title,
					left: 0
				},
				tooltip: {
					trigger: 'item'
				},
				series: [
					{
						name: '',
						type: 'pie',
						radius: this.radius,
						data: [],
						labelLine: {
							show: false
						},
						itemStyle: {
							normal: {
								color: (colors) => {
									const color = this.colorList[colors.dataIndex];
									return color ? color : colors.color;
								}
							},
						},
						label: {
							normal: {
								show: this.radius !== '70%',
								position: 'center',
								color: '#4c4a4a',
								formatter: '',
								rich: {
									total: {
										fontSize: 22,
										color: '#333333',
										fontWeight: 'bold'
									},
									active: {
										fontSize: 14,
										color: '#999999',
										lineHeight: 30,
									},
								}
							},
							emphasis: {
								show: this.radius !== '70%',
							}
						}
					}
				]
			}
		}
	},
	mounted() {
		this.charts = echarts.init(document.getElementById(this.id));
		window.setTimeout(() => {
			this.resize()
		}, 300)
		this.initCharts(this.items);
		window.addEventListener('resize', this.resize)
	},
	methods: {
		resize() {
			if (this.charts) {
				this.charts.resize();
			}
		},
		initCharts(v) {
			this.options.series[0].label.normal.formatter = '{total|' + v[v.length - 1].num + '}' + '\n\r' + '{active|总订单数}'
			this.options.series[0].name = this.title;
			let data = []
			v.forEach((item, index) => {
				if (index !== v.length - 1) {
					data.push({name: item.type, value: this.type === 'amount' ? item.amount : item.num})
				}
			})
			this.options.series[0].data = data
			if (this.charts) {
				this.charts.clear()
				this.charts.setOption(this.options)
			}
		}
	},
	destroyed() {
		window.removeEventListener('resize', this.resize)
	},
	watch: {
		items: {
			handler(v) {
				this.initCharts(v)
			}
		}
	}
}
</script>

<style scoped>

</style>
